import React from 'react'

function NavTitle(props) {
  const { children, className = '', ...other } = props
  return (
    <div
      className={`${className} d-grid align-content-center`}
      style={{ height: '72px', position: 'sticky' }}
      {...other}
    >
      <div className="d-flex">{children}</div>
    </div>
  )
}

export default NavTitle
