import React, { useState } from 'react'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import CustomTextField from './mui/text-field'
import { IconButton, InputAdornment } from '@mui/material'

export default function InputPasswordCutom({ value, onChange }) {
  const [isShowPassword, setIsShowPassword] = useState(false)
  const regex = /^[A-Za-z0-9._@!*#]+$/

  return (
    <CustomTextField
      value={value}
      id="icons-adornment-password"
      onChange={(e) => {
        if (e.target.value.match(regex) && e.target.value.length <= 16) {
          onChange(e)
        } else {
          if (e.target.value.length < 1) {
            onChange(e)
          }
        }
      }}
      type={isShowPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => setIsShowPassword(!isShowPassword)}
              onMouseDown={(e) => e.preventDefault()}
              aria-label="toggle password visibility"
            >
              {isShowPassword ? (
                <BsFillEyeFill size={16} />
              ) : (
                <BsFillEyeSlashFill size={16} />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
