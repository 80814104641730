import React, { useState } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MODE } from '../../../Constants'
import * as loginActions from '../../../actions/login.action'
import { FiLogOut, FiSun } from 'react-icons/fi'
import { updateMode } from '../../../actions/mode.action'
import { PAGE_LOGIN, PAGE_PROFILE } from '../../../router/routes'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { FaAngleDown, FaRegUser } from 'react-icons/fa'

import { BsMoonStarsFill } from 'react-icons/bs'
import IconLogo from '../../../images/IconLogo.png'
import { Image } from 'react-bootstrap'

function HeaderPage({ childrenTitle }) {
  const mode = useSelector((state) => state.modeReducer.mode)

  const profile = useSelector((state) => state.loginReducer.profile)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fnLogout = () => {
    dispatch(loginActions.logout(navigate, PAGE_LOGIN))
    navigate(PAGE_LOGIN)
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenuItem = () => {
    setAnchorEl(null)
  }

  const menu = [
    {
      label: 'My Profile',
      click: () => {
        navigate(PAGE_PROFILE)
      },
      icon: <FaRegUser size={16} className="me-2" />
    },
    {
      label: mode === '0' ? 'Light Mode' : 'Dark Mode',
      click: () => {
        let x = mode === '0' ? '1' : '0'
        dispatch(updateMode(x))
        localStorage.setItem(MODE, x)
      },
      icon:
        mode !== '0' ? (
          <BsMoonStarsFill size={16} className="me-2" />
        ) : (
          <FiSun size={16} className="me-2" />
        )
    },
    {
      label: 'Log out',
      click: fnLogout,
      icon: <FiLogOut size={16} className="me-2" />
    }
  ]
  return (
    <div
      minHeight="none"
      style={{
        height: 58,
        backdropFilter: 'blur(48px)',
        backgroundColor: 'var(--bg-nav)'
      }}
      className="d-flex justify-content-center shadow-sm fixed-top"
    >
      <Container fluid className="d-flex justify-content-between  ">
        <div className=" my-auto  d-flex ms-2">
          <Image src={IconLogo} fluid width={50} />
        </div>

        <div className="d-flex ">
          {/* <SwitchModeDark
            checked={mode}
            onClick={() => {
              let x = mode === '0' ? '1' : '0'
              dispatch(updateMode(x))
              localStorage.setItem(MODE, x)
            }}
          /> */}

          {/* <Button
            size="sm"
            variant={mode !== '0' ? 'light' : 'primary'}
            className="rounded-circle my-auto border me-3"
            style={{ height: 33, width: 33 }}
            onClick={() => {
              let x = mode === '0' ? '1' : '0'
              dispatch(updateMode(x))
              localStorage.setItem(MODE, x)
            }}
          >
            {mode !== '0' ? <BsSun size={16} /> : <BsMoonStarsFill />}
          </Button> */}

          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <label style={{ fontSize: '14px' }} className="pointer-event">
              {profile?.email} <FaAngleDown className="ms-1" />
            </label>
          </IconButton>

          <Menu
            keepMounted
            id="long-menu"
            anchorEl={anchorEl}
            onClose={handleCloseMenuItem}
            open={Boolean(anchorEl)}
          >
            {menu.map((p) => (
              <MenuItem
                key={p.label}
                // selected={option === 'Pyxis'}
                onClick={p.click}
              >
                {p.icon} {p.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Container>
    </div>
  )
}
export const MenuPage = styled.div`
  .MuiDrawer-paper {
    background-color: #7367f0;
    left: unset;
    right: unset;
    overflow-x: hidden;
    transition: width 0.25s ease-in-out 0s, box-shadow 0.25s ease-in-out 0s;
  }
  .MenuPage {
    flex: 0 0 auto;
    overflow-x: hidden;
    transition: width 0.25s ease-in-out 0s;
    width: 260px;
  }
  @media (max-width: 1200px) {
    .MenuPage {
      width: 0px;
    }
    .MuiDrawer-paper {
      display: none;
    }
  }
`

export const Title = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
`

export const MenuBody = styled.div`
  color: rgba(51, 48, 60, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: 100;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(51 48 60 / 3%) 0px 3px 9px 1px,
    rgb(51 48 60 / 2%) 0px 8px 9px 0px, rgb(51 48 60 / 1%) 0px 1px 6px 4px;
  width: 260px;
  border-right: 0px solid;
`

export const MenuHeader = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-right: 1.125rem;
  transition: padding 0.25s ease-in-out 0s;
  min-height: 64px;
  padding-left: 1.125rem;
`

export const MenuContent = styled.div`
  position: relative;
  overflow: hidden;

  .scrollbar-container {
    position: relative;
    height: 100%;
  }
  .ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }
`

export const SubMenu = styled.li`
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin-top: 0.25rem;
  flex-direction: column;
  padding-left: 0px !important;
  padding-right: 0px !important;
`
export default HeaderPage
