import React from 'react'

export default function DivTextCustom({ children, className = '', other }) {
  return (
    <div className={`text-default ${className}`} {...other}>
      {children}
    </div>
  )
}


