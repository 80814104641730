import { useEffect, useState } from 'react'
import { versionBackAPI } from '../services/auth'

export default function UseVersionBackend() {
  const [data, setData] = useState('')
  useEffect(() => {
    const getData = async () => {
      const resp = await versionBackAPI()
      if (!resp) return

      setData(resp.result.version)
    }
    getData()
  }, [])

  return data
}
