// ** React Import
import { TextField } from '@mui/material'
import { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import typography from '../../../theme/typography'

// ** MUI Imports
const whiteColor = '#FFF'
const lightColor = '47, 43, 61'
const darkColor = '208, 212, 241'
const darkPaperBgColor = '#2F3349'
const mainColor = lightColor

export const theme = {
  typography: {
    body2: { fontSize: 13.125 },
    fontFamily: typography.fontFamily
  },
  palette: {
    secondary: {
      light: '#B2B4B8',
      main: '#A8AAAE',
      dark: '#949699',
      contrastText: whiteColor
    },
    error: {
      light: '#ED6F70',
      main: '#EA5455',
      dark: '#CE4A4B',
      contrastText: whiteColor
    },
    warning: {
      light: '#FFAB5A',
      main: '#FF9F43',
      dark: '#E08C3B',
      contrastText: whiteColor
    },
    info: {
      light: '#1FD5EB',
      main: '#00CFE8',
      dark: '#00B6CC',
      contrastText: whiteColor
    },
    success: {
      light: '#42CE80',
      main: '#28C76F',
      dark: '#23AF62',
      contrastText: whiteColor
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#F5F5F5',
      A200: '#EEEEEE',
      A400: '#BDBDBD',
      A700: '#616161'
    },
    text: {
      primary: `rgba(${mainColor}, 0.78)`,
      secondary: `rgba(${mainColor}, 0.68)`,
      disabled: `rgba(${mainColor}, 0.42)`
    },
    primary: {
      light: '#8479F2',
      main: '#7367F0',
      dark: '#655BD3',
      contrastText: whiteColor
    },

    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      lightPaperBg: whiteColor,
      darkPaperBg: darkPaperBgColor
    },
    divider: `rgba(${mainColor}, 0.16)`,
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.06)`,
      selectedOpacity: 0.06,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  },
  shadows: (mode) => {
    if (mode === 'light') {
      return [
        'none',
        '0px 2px 4px 0px rgba(47, 43, 61, 0.12)',
        '0px 2px 6px 0px rgba(47, 43, 61, 0.14)',
        '0px 3px 8px 0px rgba(47, 43, 61, 0.14)',
        '0px 3px 9px 0px rgba(47, 43, 61, 0.15)',
        '0px 4px 10px 0px rgba(47, 43, 61, 0.15)',
        '0px 4px 11px 0px rgba(47, 43, 61, 0.16)',
        '0px 4px 18px 0px rgba(47, 43, 61, 0.1)',
        '0px 4px 13px 0px rgba(47, 43, 61, 0.18)',
        '0px 5px 14px 0px rgba(47, 43, 61, 0.18)',
        '0px 5px 15px 0px rgba(47, 43, 61, 0.2)',
        '0px 5px 16px 0px rgba(47, 43, 61, 0.2)',
        '0px 6px 17px 0px rgba(47, 43, 61, 0.22)',
        '0px 6px 18px 0px rgba(47, 43, 61, 0.22)',
        '0px 6px 19px 0px rgba(47, 43, 61, 0.24)',
        '0px 7px 20px 0px rgba(47, 43, 61, 0.24)',
        '0px 7px 21px 0px rgba(47, 43, 61, 0.26)',
        '0px 7px 22px 0px rgba(47, 43, 61, 0.26)',
        '0px 8px 23px 0px rgba(47, 43, 61, 0.28)',
        '0px 8px 24px 6px rgba(47, 43, 61, 0.28)',
        '0px 9px 25px 0px rgba(47, 43, 61, 0.3)',
        '0px 9px 26px 0px rgba(47, 43, 61, 0.32)',
        '0px 9px 27px 0px rgba(47, 43, 61, 0.32)',
        '0px 10px 28px 0px rgba(47, 43, 61, 0.34)',
        '0px 10px 30px 0px rgba(47, 43, 61, 0.34)'
      ]
    } else {
      return [
        'none',
        '0px 2px 4px 0px rgba(15, 20, 34, 0.12)',
        '0px 2px 6px 0px rgba(15, 20, 34, 0.14)',
        '0px 3px 8px 0px rgba(15, 20, 34, 0.14)',
        '0px 3px 9px 0px rgba(15, 20, 34, 0.15)',
        '0px 4px 10px 0px rgba(15, 20, 34, 0.15)',
        '0px 4px 11px 0px rgba(15, 20, 34, 0.16)',
        '0px 4px 18px 0px rgba(15, 20, 34, 0.1)',
        '0px 4px 13px 0px rgba(15, 20, 34, 0.18)',
        '0px 5px 14px 0px rgba(15, 20, 34, 0.18)',
        '0px 5px 15px 0px rgba(15, 20, 34, 0.2)',
        '0px 5px 16px 0px rgba(15, 20, 34, 0.2)',
        '0px 6px 17px 0px rgba(15, 20, 34, 0.22)',
        '0px 6px 18px 0px rgba(15, 20, 34, 0.22)',
        '0px 6px 19px 0px rgba(15, 20, 34, 0.24)',
        '0px 7px 20px 0px rgba(15, 20, 34, 0.24)',
        '0px 7px 21px 0px rgba(15, 20, 34, 0.26)',
        '0px 7px 22px 0px rgba(15, 20, 34, 0.26)',
        '0px 8px 23px 0px rgba(15, 20, 34, 0.28)',
        '0px 8px 24px 6px rgba(15, 20, 34, 0.28)',
        '0px 9px 25px 0px rgba(15, 20, 34, 0.3)',
        '0px 9px 26px 0px rgba(15, 20, 34, 0.32)',
        '0px 9px 27px 0px rgba(15, 20, 34, 0.32)',
        '0px 10px 28px 0px rgba(15, 20, 34, 0.34)',
        '0px 10px 30px 0px rgba(15, 20, 34, 0.34)'
      ]
    }
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  breakpoints: () => ({
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  })
}
const TextFieldStyled = styled(TextField)(() => ({
  alignItems: 'flex-start',
  '& .MuiFormControl-root .MuiTextField-root': {
    // width: '100%'
  },

  '& .MuiInputLabel-root': {
    fontFamily: theme.typography.fontFamily,
    transform: 'none',
    lineHeight: 1.154,
    position: 'relative',
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    color: `var(--color-on-base-content-subtle) !important`
  },
  '& .MuiInputBase-root': {
    borderRadius: 8,
    width: '100%',
    height: 34,
    backgroundColor: 'transparent !important',
    border: `1px solid var(--color-on-base-border-disable)`,
    transition: `border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    '&:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):hover': {
      borderColor: `var(--color-on-base-content-disable)`
    },
    '&:before, &:after': {
      display: 'none'
    },
    '&.MuiInputBase-sizeSmall': {
      borderRadius: 6
    },
    '&.Mui-error': {
      borderColor: `var(--color-on-base-content-disable)`
    },
    '&.Mui-focused': {
      boxShadow: theme.shadows[2],
      '& .MuiInputBase-input:not(.MuiInputBase-readOnly):not([readonly])::placeholder':
        {
          transform: 'translateX(4px)'
        },
      '&.MuiInputBase-colorPrimary': {
        borderColor: theme.palette.primary.main
      },
      '&.MuiInputBase-colorSecondary': {
        borderColor: theme.palette.secondary.main
      },
      '&.MuiInputBase-colorInfo': {
        borderColor: theme.palette.info.main
      },
      '&.MuiInputBase-colorSuccess': {
        borderColor: theme.palette.success.main
      },
      '&.MuiInputBase-colorWarning': {
        borderColor: theme.palette.warning.main
      },
      '&.MuiInputBase-colorError': {
        borderColor: theme.palette.error.main
      },
      '&.Mui-error': {
        borderColor: theme.palette.error.main
      }
    },
    '&.Mui-disabled': {
      backgroundColor: `${theme.palette.action.selected} !important`
    },
    '& .MuiInputAdornment-root': {
      marginTop: '0 !important'
    }
  },
  '& .MuiInputBase-input': {
    color: 'var(--color-on-base-content-subtle)',
    '&:not(textarea)': {
      padding: '15.5px 13px'
    },
    height: '1.1rem',
    fontFamily: theme.typography.fontFamily,
    '&:not(textarea).MuiInputBase-inputSizeSmall': {
      padding: '7.5px 13px'
    },
    '&:not(.MuiInputBase-readOnly):not([readonly])::placeholder': {
      // transition: theme.transitions.create(['opacity', 'transform'], {
      //   duration: theme.transitions.duration.shorter
      // })
      transition: `border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #e7f0fe inset',
      WebkitTextFillColor: 'var(--color-on-base-content-subtle) !important',
      borderRadius: '6px'
    },

    // ** For Autocomplete
    '&.MuiInputBase-inputAdornedStart:not(.MuiAutocomplete-input)': {
      paddingLeft: 0,
      fontFamily: theme.typography.fontFamily
    },
    '&.MuiInputBase-inputAdornedEnd:not(.MuiAutocomplete-input)': {
      paddingRight: 0
    }
  },
  '& .MuiFormHelperText-root': {
    lineHeight: 1.154,
    margin: theme.spacing(1, 0, 0),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    '&.Mui-error': {
      color: theme.palette.error.main
    }
  },

  // ** For Select
  '& .MuiSelect-select:focus, & .MuiNativeSelect-select:focus': {
    backgroundColor: 'transparent'
  },
  '& .MuiSelect-filled .MuiChip-root': {
    height: 22
  },

  // ** For Autocomplete
  '& .MuiAutocomplete-input': {
    paddingLeft: '6px !important',
    paddingTop: '7.5px !important',
    paddingBottom: '7.5px !important',
    '&.MuiInputBase-inputSizeSmall': {
      paddingLeft: '6px !important',
      paddingTop: '2.5px !important',
      paddingBottom: '2.5px !important'
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    paddingTop: '8px !important',
    paddingLeft: '8px !important',
    paddingBottom: '8px !important',
    '&:not(.MuiInputBase-sizeSmall).MuiInputBase-adornedStart': {
      paddingLeft: '13px !important'
    },
    '&.MuiInputBase-sizeSmall': {
      paddingTop: '5px !important',
      paddingLeft: '5px !important',
      paddingBottom: '5px !important',
      '& .MuiAutocomplete-tag': {
        margin: 2,
        height: 22
      }
    }
  },

  // ** For Textarea
  '& .MuiInputBase-multiline': {
    padding: '15.25px 13px',
    '&.MuiInputBase-sizeSmall': {
      padding: '7.25px 13px'
    },
    '& textarea.MuiInputBase-inputSizeSmall:placeholder-shown': {
      overflowX: 'hidden'
    }
  },

  // ** For Date Picker
  '& + .react-datepicker__close-icon': {
    top: 11,
    '&:after': {
      fontSize: '1.6rem !important'
    }
  }
}))

const CustomTextField = forwardRef((props, ref) => {
  // ** Props
  const { size = 'small', InputLabelProps, ...rest } = props

  return (
    <TextFieldStyled
      sx={{ width: '100%' }}
      size={size}
      inputRef={ref}
      {...rest}
      variant="filled"
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
    />
  )
})

export default CustomTextField
