import './font/index'
import React from 'react'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import reducers from './reducers'
import thunk from 'redux-thunk'
import ThemeComponent from './theme/ThemeComponent'
const middlewares = [thunk]

export const store = createStore(reducers, applyMiddleware(...middlewares))
const container = document.getElementById('root')

const root = createRoot(container)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeComponent>
        <App />
      </ThemeComponent>
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
