import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { BsExclamationCircle } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { updateToken } from '../../actions/login.action'
import { PageDefault, PROFILE, REMEMBER, RETOKEN, TOKEN } from '../../Constants'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoginAPI } from '../../services/auth'
import LabelCustom from '../../components/label-custom'
import ErrorCustomFalse from '../../components/error/ErrorCustomFalse'
import ErrorCustom500 from '../../components/error/ErrorCustom500'
import { PAGE_FORGOTPASSWORD, PAGE_REGISTER } from '../../router/routes'
import InputPasswordCutom from '../../components/InputPasswordCutom'
import { DecryptParamJson, EncryptParamJson } from '../../lib/crypto'

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required()
  })
  .required()

export default function LoginPage() {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()
  const [isLogin, setIsLogin] = React.useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const remember =
      DecryptParamJson(localStorage.getItem(REMEMBER) ?? '{}') ?? '{}'
    const dataLacal = remember ?? {}

    setValue('email', dataLacal?.email ?? '')
    setValue('password', dataLacal?.password ?? '')
    setValue('remember', dataLacal?.remember ?? false)
  }, [setValue])

  const onSubmit = async (d) => {
    try {
      setIsLogin(true)
      const res = await LoginAPI({
        email: d.email,
        password: d.password
      })
      setIsLogin(false)
      if (!res) return
      if (res.status) {
        localStorage.setItem(RETOKEN, res.refreshToken)
        localStorage.setItem(TOKEN, res.accessToken)
        localStorage.setItem(PROFILE, JSON.stringify(res.result))

        dispatch(updateToken(res.accessToken, res.refreshToken, res.result))
        if (d?.remember) {
          let _enData = EncryptParamJson(d)
          localStorage.setItem(REMEMBER, _enData)
        } else {
          localStorage.setItem(REMEMBER, '{}')
        }
        navigate(PageDefault)
      } else {
        ErrorCustomFalse(res)
      }
    } catch (error) {
      setIsLogin(false)
      ErrorCustom500(error)
    }
  }
  return (
    <>
      <div className="row ">
        <div className="col-lg-11 mx-auto">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <LabelCustom text={'Email'} />

              <Controller
                name={'email'}
                control={control}
                render={({ field: { onChange, value = '' } }) => (
                  <Form.Control
                    type="email"
                    placeholder=""
                    id=""
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              {errors.email && (
                <span className="text-danger small fw-light">
                  <BsExclamationCircle /> Please choose your email.
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <LabelCustom text={'Password'} />
              <Controller
                name={'password'}
                control={control}
                render={({ field: { onChange, value = '' } }) => (
                  <InputPasswordCutom onChange={onChange} value={value} />
                )}
              />
              {errors.password && (
                <span className="text-danger small fw-light">
                  <BsExclamationCircle /> Please choose your password.
                </span>
              )}
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Controller
                name={'remember'}
                control={control}
                render={({ field: { onChange, value = false } }) => (
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicCheckbox"
                    value={value}
                    onChange={onChange}
                  >
                    <Form.Check
                      checked={value}
                      type="checkbox"
                      label="Remember me"
                      className="small"
                    />
                  </Form.Group>
                )}
              />
              <Link to={PAGE_FORGOTPASSWORD} className="text-decoration-none">
                Forgot Password?
              </Link>
            </div>

            <div className="d-grid gap-2 mt-3">
              <Button
                disabled={isLogin}
                className="radius-primary btn-sky"
                type="submit"
              >
                {isLogin ? 'Loading...' : 'Login'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className="mt-3 text-center">
        <p>
          New on our platform?{' '}
          <Link to={PAGE_REGISTER} className="text-decoration-none">
            Register
          </Link>
        </p>
      </div>
    </>
  )
}
