import React from 'react'
import Cleave from 'cleave.js/react'
function InputNamberTaxID({ value, onChange, ...other }) {
  const options = { blocks: [13], uppercase: true, numericOnly: true }
  const isValid = value.length === 10
  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '')
    // Remove non-numeric characters
    onChange(inputValue)
  }
  return (
    <div>
      <Cleave
        className={`form-control`}
        value={value}
        onChange={handleInputChange}
        options={options}
        id="prefix"
        {...other}
      />
      {!isValid && value.length !== 13 ? (
        <div className="invalid-feedback">
          โปรดระบุเลขประจำตัวผู้เสียภาษี (Tax ID 13 หลัก)
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default InputNamberTaxID
