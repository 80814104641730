import axios from 'axios'
import { API_URL, AUTH } from '../Constants'
import SwalErrorCustom from './swal-error'

export const postAuth = async (path, body = {}) => {
  return await axios
    .post(`${API_URL}${path}`, body, {
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json',
        authorization: AUTH
      }
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      SwalErrorCustom(error)
    })
}

export const getAuth = async (path, params = {}) => {
  return await axios({
    method: 'GET',
    url: `${API_URL}${path}`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      authorization: AUTH
    },
    params
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      SwalErrorCustom(error)
    })
}
