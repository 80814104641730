import { useSelector } from 'react-redux'
import DefaultIconDark from '../../images/default-image-dark.png'
import DefaultIcon from '../../images/default-image.png'
import { Image } from 'react-bootstrap'
export default function ImageDefault({ height = 180 }) {
  const mode = useSelector((state) => state.modeReducer.mode)
  return (
    <Image
      src={mode === '0' ? DefaultIconDark : DefaultIcon}
      style={{ height }}
      fluid
    />
  )
}
