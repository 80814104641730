export const PAGE_LOGIN = '/login'
export const PAGE_REGISTER = '/register'
export const PAGE_REGISTER_VERIFY = '/register/verify'
export const PAGE_SETPASSWORD = '/setting-password'
export const PAGE_FORGOTPASSWORD = '/forgot-password'
export const PAGE_HELPPAGE = '/help'
export const PAGE_HOME = '/home'

export const PAGE_PROFILE = '/profile'
export const PAGE_VIEWDOC = '/view-doc'
export const PAGE_BIllING = '/billing'
export const PAGE_BIllING_UPDATE = '/billing/update'
export const PAGE_VIEW_UPDATE = '/view-doc/update'

export const MENU_DEFAULT = '/'
export const MENU_HOME = '/form-elements'
