import { GET_TOKEN } from '../actions/login.action'
import { LOGOUT } from '../Constants'

const initState = {
  token: '',
  profile: ''
}

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        profile: action.payload.profile
      }
    case LOGOUT:
      return initState
    default:
      return state
  }
}

export default loginReducer
