import { getAuth, postAuth } from '../utils/auth'

const path = 'auth/'
export const registerAPI = async (data) => {
  return postAuth(`${path}register`, data)
}

export const createPasswordAPI = async (data) => {
  return postAuth(`${path}createPassword`, data)
}

export const resetPasswordAPI = async (data) => {
  return postAuth(`${path}resetPassword`, data)
}

export const LoginAPI = async (data) => {
  return postAuth(`${path}login`, data)
}

export const emailAPI = async (data) => {
  return getAuth(`${path}email`, data)
}

export const versionFrontAPI = async () => {
  return getAuth('auth/versionFront')
}

export const versionBackAPI = async () => {
  return getAuth('auth/version')
}
