// ** Overrides Imports

import MuiAutocomplete from './autocomplete'
import MuiAccordion from './accordion'

const Overrides = (settings) => {
  const { skin } = settings
  const accordion = MuiAccordion()
  const autocomplete = MuiAutocomplete(skin)

  return Object.assign(accordion, autocomplete)
}

export default Overrides
