import { LOGOUT, PROFILE, TOKEN } from '../Constants'

export const GET_TOKEN = 'GET_TOKEN'

export const updateToken = (token, retoken, profile) => {
  return {
    type: GET_TOKEN,
    payload: {
      token,
      profile,
      retoken
    }
  }
}
export const restoreLogin = () => {
  const token = localStorage.getItem(TOKEN)
  const profile = JSON.parse(localStorage.getItem(PROFILE))
  return {
    type: GET_TOKEN,
    payload: {
      token,
      profile
    }
  }
}

export const logout = (navigate, path) => {
  return (dispatch) => {
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(PROFILE)
    dispatch({
      type: LOGOUT
    })
    navigate(path)
  }
}
