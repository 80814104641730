import './App.css'
import { Route, Routes, Navigate } from 'react-router-dom'
import PublicRoutes from './router/public.routes'
import {
  PAGE_FORGOTPASSWORD,
  PAGE_HOME,
  PAGE_LOGIN,
  PAGE_PROFILE,
  PAGE_REGISTER,
  PAGE_REGISTER_VERIFY,
  PAGE_SETPASSWORD
} from './router/routes'
import { Container } from 'react-bootstrap'

import ProtectedRoutes from './router/protected.routes'
import { PageDefault } from './Constants'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import * as loginActions from './actions/login.action'
import * as modeActions from './actions/mode.action'
import RegisterPage from './pages/auths/registerPage'
import LoginPage from './pages/auths/loginPage'
import SettingPasswordPage from './pages/auths/settingPasswordPage'
import ForgotPasswordPage from './pages/auths/ForgotPasswordPage'
import ProfileIndexPage from './pages/profile'

import VerifyRegisterPage from './pages/auths/VerifyRegisterPage'

import FooterAuth from './pages/auths'
import HomePage from './pages/home'
import NotFound404 from './pages/NotFound404'

function App() {
  const dispatch = useDispatch()
  const mode = useSelector((state) => state.modeReducer.mode)

  useEffect(() => {
    document.documentElement.setAttribute('data-dark-mode', mode)
  }, [mode])

  useEffect(() => {
    dispatch(loginActions.restoreLogin())
    dispatch(modeActions.restoreMode())

    return () => {
      dispatch(loginActions.restoreLogin())
      dispatch(modeActions.restoreMode())
    }
  }, [dispatch])

  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path={PageDefault} element={<Navigate to={PAGE_HOME} />} />

        <Route path={PAGE_PROFILE} element={<ProfileIndexPage />} />

        <Route path={PAGE_HOME} element={<HomePage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      {/* Public user */}
      <Route element={<PublicRoutes />}>
        <Route element={<FooterAuth />}>
          <Route path={PAGE_LOGIN} element={<LoginPage />} />
          <Route path={PAGE_REGISTER} element={<RegisterPage />} />
          <Route path={PAGE_REGISTER_VERIFY} element={<VerifyRegisterPage />} />
          <Route path={PAGE_SETPASSWORD} element={<SettingPasswordPage />} />
          <Route path={PAGE_FORGOTPASSWORD} element={<ForgotPasswordPage />} />
        </Route>
      </Route>
    </Routes>
  )
}
const NotFound = () => (
  <Container fluid>
    <div className="row">
      <div className="col-lg-12 mx-auto text-center ">
        <div
          style={{ minHeight: '85vh' }}
          className="mt-3 d-flex justify-content-center align-items-center"
        >
          <NotFound404 />
        </div>
      </div>
    </div>
  </Container>
)
export default App
