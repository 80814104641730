import SwalCustom from '../alert/swal-custom'

function ErrorCustom500(error) {
  return SwalCustom({
    title: 'พบข้อผิดพลาด',
    text: `กรุณาบันทึกภาพหน้าจอนี้ แล้วแจ้ง IT เพื่อแก้ไขปัญหา (Ref. ${
      error.response.status ?? 0
    })`,
    icon: 'error',
    showCancelButton: false,
    confirmButtonColor: 'var(--comp-badge-color-background-error)',
    confirmButtonText: 'Close'
  })
}

export default ErrorCustom500
