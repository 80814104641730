import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { BsExclamationCircle } from 'react-icons/bs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { REMEMBER } from '../../Constants'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { createPasswordAPI, emailAPI } from '../../services/auth'
import LabelCustom from '../../components/label-custom'
import ErrorCustomFalse from '../../components/error/ErrorCustomFalse'
import ErrorCustom500 from '../../components/error/ErrorCustom500'
import InputPasswordCutom from '../../components/InputPasswordCutom'
import { PAGE_LOGIN } from '../../router/routes'
import NotFound404 from '../NotFound404'
import SwalCustomSuccess from '../../components/alert/swal-success'

export default function SettingPasswordPage() {
  let [searchParams] = useSearchParams()

  const uuid = searchParams.get('ps')
  const schema = yup
    .object({
      newPassword: yup.string().required(),
      confirmPassword: yup.string().required()
    })
    .required()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()
  const [isLogin, setIsLogin] = React.useState(false)
  const [dataEmail, setDataEmail] = useState({
    email: '',
    status: true
  })

  useEffect(() => {
    const fnGetVerify = async () => {
      const resp = await emailAPI({ uuid })
      if (!resp) return
      if (resp.status) {
        setDataEmail({ email: resp.result.email, status: true })
      } else {
        setDataEmail({ email: '', status: false })
      }
    }
    fnGetVerify()
  }, [uuid])

  const onSubmit = async (data) => {
    try {
      setIsLogin(true)
      const res = await createPasswordAPI({
        uuid,
        password: data.newPassword,
        confirmPass: data.confirmPassword
      })
      setIsLogin(false)
      if (!res) return
      if (res.status) {
        SwalCustomSuccess().then(() => {
          localStorage.setItem(
            REMEMBER,
            JSON.stringify({
              email: dataEmail.email,
              password: '',
              remember: true
            })
          )
          navigate(PAGE_LOGIN)
        })
      } else {
        ErrorCustomFalse(res)
      }
    } catch (error) {
      setIsLogin(false)
      ErrorCustom500(error)
    }
  }
  const [valuePassword, setValuePassword] = useState({ new: '', confirm: '' })
  const regex = {
    isMininmun: 8,
    charLower: /[a-z]/g,
    charUp: /[A-Z]/g,
    number: /[0-9]/g,
    speacial: /[._@!*#]/g
  }
  const [checkPassword, setCheckPassword] = useState({
    isMininmun: false,
    charLower: false,
    charUp: false,
    number: false,
    speacial: false,
    isConfirm: false
  })

  const fnCheckPassword = (value) => {
    setValuePassword({ ...valuePassword, new: value })
    setCheckPassword({
      isMininmun: value.length >= regex.isMininmun,
      charLower: !!value.match(regex.charLower)?.length,
      charUp: !!value.match(regex.charUp)?.length,
      number: !!value.match(regex.number)?.length,
      speacial: !!value.match(regex.speacial)?.length,
      isConfirm: valuePassword.confirm === value
    })
  }
  console.log('checkPassword', checkPassword)
  return (
    <>
      {dataEmail.status ? (
        <div className="row mt-3">
          <div className="col-lg-11 mx-auto mt-3">
            <h6 className="mb-1">Set Password</h6>
            <p>for {dataEmail.email}</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Group className="mb-3">
                  <LabelCustom text={'Password'} />
                  <Controller
                    name={'newPassword'}
                    control={control}
                    render={({ field: { onChange, value = '' } }) => (
                      <InputPasswordCutom
                        value={value}
                        onChange={(e) => {
                          fnCheckPassword(e.target.value)
                          onChange(e)
                        }}
                      />
                    )}
                  />
                  {errors.newPassword && (
                    <span className="text-danger small fw-light">
                      <BsExclamationCircle /> Please choose your password.
                    </span>
                  )}
                  <div className=" small fw-light mt-2">
                    <ul>
                      <li
                        className={`${
                          checkPassword.isMininmun
                            ? 'text-success'
                            : 'text-secondary'
                        }`}
                      >
                        {regex.isMininmun}-16 characters
                      </li>
                      <li
                        className={`${
                          checkPassword.charLower
                            ? 'text-success'
                            : 'text-secondary'
                        }`}
                      >
                        At least one lower case character (a-z)
                      </li>
                      <li
                        className={`${
                          checkPassword.charUp
                            ? 'text-success'
                            : 'text-secondary'
                        }`}
                      >
                        At least one capital letter (A-Z)
                      </li>
                      <li
                        className={`${
                          checkPassword.number
                            ? 'text-success'
                            : 'text-secondary'
                        }`}
                      >
                        At least one number (0-9)
                      </li>
                      <li
                        className={`${
                          checkPassword.speacial
                            ? 'text-success'
                            : 'text-secondary'
                        }`}
                      >
                        Special Characters (._@!*#)
                      </li>
                    </ul>
                  </div>
                </Form.Group>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Group className="mb-3">
                  <LabelCustom text={'Confrim password'} />
                  <Controller
                    name={'confirmPassword'}
                    control={control}
                    render={({ field: { onChange, value = '' } }) => (
                      <InputPasswordCutom
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                          setValuePassword({
                            ...valuePassword,
                            confirm: e.target.value
                          })

                          setCheckPassword({
                            ...checkPassword,
                            isConfirm: valuePassword.new === e.target.value
                          })
                        }}
                      />
                    )}
                  />
                  {errors.confirmPassword ? (
                    <span className="text-danger small fw-light">
                      <BsExclamationCircle /> Please choose your password.
                    </span>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Form.Group>
              {!checkPassword.isConfirm && valuePassword.confirm ? (
                <div className="text-danger small mt-3 fw-light text-center">
                  <BsExclamationCircle /> Password Not Match
                </div>
              ) : (
                <></>
              )}
              <div className="d-grid gap-2 mt-5">
                <Button
                  disabled={
                    isLogin ||
                    !(
                      checkPassword.isMininmun &&
                      checkPassword.charLower &&
                      checkPassword.charUp &&
                      checkPassword.number &&
                      checkPassword.speacial &&
                      checkPassword.isConfirm
                    )
                  }
                  className="radius-primary btn-sky"
                  type="submit"
                >
                  {isLogin ? 'Save Password...' : 'Save Password'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <NotFound404 />
        </div>
      )}
    </>
  )
}
