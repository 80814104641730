import React from 'react'
import { Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ImgLogoDark from '../../images/logo-dark.png'
import ImgLogoLight from '../../images/logo-light.png'

export default function ImageLogo({ width = 200 }) {
  const mode = useSelector((state) => state.modeReducer.mode)
  return mode === '0' ? (
    <Image src={ImgLogoDark} width={width} />
  ) : (
    <Image src={ImgLogoLight} width={width} />
  )
}
