import { useSelector } from 'react-redux'
import BillingIconDark from '../../images/billing-icon-dark.png'
import BillingIcon from '../../images/billing-icon.png'
import { Image } from 'react-bootstrap'

export default function ImageBiling({ height = 180 }) {
  const mode = useSelector((state) => state.modeReducer.mode)
  return (
    <Image
      src={mode === 'dark' ? BillingIconDark : BillingIcon}
      style={{ height }}
      fluid
    />
  )
}
