import { MENU_DEFAULT } from './router/routes'

export const TITLE = 'HR Management System'
export const URL_PROFILE = 'https://portal.skyict.co.th/time_sheet'
export const LOGOUT = 'LOGOUT'
export const TOKEN = 'TOKEN'
export const RETOKEN = 'RETOKEN'
export const PROFILE = 'PROFILE'
export const REMEMBER = 'REMEMBER'
export const ID_DETAIL = 'DETAIL'
export const MODE = 'MODE'

export const URL_BILLING_DEV = 'https://dv-billing.skyict.co.th'
export const URL_BILLING_PRODUCTION = 'https://billing.skyict.co.th'
export const TEXT_ERROR =
  'หากมีข้อสงสัยกรุณาบันทึกภาพหน้าจอนี้ แล้วแจ้ง IT ที่ it-app@skyict.co.th เพื่อแก้ไข'
export const TEXTSUCCESS =
  'The information has been successfully saved into the system.'

export const API_URL = `${process.env.REACT_APP_API_URL}api/`
export const AUTH = `${process.env.REACT_APP_AUTHEN}`
export const FOOTER_VERSION = 'V.1.0.0'
export const PageDefault = MENU_DEFAULT

export const activeStyle = {
  backgroundColor: 'rgb(178 214 255)',
  color: '#ffff'
}
