import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PAGE_LOGIN } from './routes'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import FooterLayout from '../components/layouts/footer'
import { PROFILE, TOKEN } from '../Constants'
import HeaderPage from '../components/layouts/header'

const ProtectedRoutes = () => {
  const token = localStorage.getItem(TOKEN)
  const profile = JSON.parse(localStorage.getItem(PROFILE))

  return token && profile ? (
    <BodyPage style={{ backgroundImage: '' }}>
      {/* <MenuPage /> */}

      <BodyContent>
        <HeaderPage />
        <Container
          className=""
          style={{ minHeight: '87vh', marginTop: '58px' }}
        >
          {/* <h3 className="mb-0 text-center mt-3 mb-0">สวัสดีกรมสรรพากร</h3> */}
          <Outlet />
        </Container>
        <div className="mt-3">
          <FooterLayout />
        </div>
      </BodyContent>
    </BodyPage>
  ) : (
    <Navigate to={PAGE_LOGIN} />
  )
}
const BodyPage = styled.div`
  height: 100%;
  display: flex;
`
const BodyContent = styled.div`
  // margin-left: 20px;
  // margin-right: 20px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: 0px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 0 !important;
  }
`
export default ProtectedRoutes
