import React, { useEffect, useState } from 'react'
import CardCustom from '../../components/CardCustom'
import { Link } from 'react-router-dom'
import { getSystemAPI } from '../../services/group'
import styled from '@emotion/styled'
import { fnCheckMenuList } from '../../components/page/home/MapMunu'
import LoadingCustom from '../../components/custom/LoadingCustom'
import { useSelector } from 'react-redux'
export default function HomePage() {
  const [menuSystem, setMenuSystem] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = useSelector((state) => state.loginReducer.token)

  useEffect(() => {
    const getSystem = async () => {
      try {
        const resp = await getSystemAPI()
        setIsLoading(false)
        if (!resp) return
        if (resp.status) {
          const list = resp.result.map((d) => {
            const newMenu = fnCheckMenuList(d)
            return { ...d, ...newMenu, token: token }
          })
          setMenuSystem(list)
        } else {
          setMenuSystem([])
        }
      } catch (error) {
        setMenuSystem([])
      }
    }
    if (token) {
      getSystem()
    }
  }, [token])

  return (
    <div className="mt-5">
      {!isLoading ? (
        <BoxImage>
          {menuSystem.map((d, i) => (
            <CardCustom minHeight="" key={`${i}-card`}>
              <Link
                to={d.isChcekLogin ? `${d.link}${d.token}` : d.link}
                target={d.target}
                className="p-3 text-reset text-decoration-none"
              >
                <div className="mb-4 ">{d.icon}</div>
                <h5 className="">{d.system}</h5>
                <p className="">Office Service</p>
              </Link>
            </CardCustom>
          ))}
        </BoxImage>
      ) : (
        <div>
          <LoadingCustom />
        </div>
      )}
    </div>
  )
}

const BoxImage = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
`

