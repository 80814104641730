import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ImageBanner from '../images/img-question.png'
export default function NotFound404() {
  return (
    <div className="p-3">
      <div>
        <h1 className="text-primary">Page Not Found!</h1>
        <p className="fw-light">
          We're sorry, the page you requested could not be found Please go back
          to the homepage
        </p>
        <Link to="/" className="btn-light  px-4 btn shadow-sm">
          Go Home
        </Link>
        <div className="mt-5">
          <Image src={ImageBanner} width={300} fluid />
        </div>
      </div>
    </div>
  )
}
