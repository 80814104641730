import { AES, enc } from 'crypto-js'
import { AUTH } from '../Constants'

export const EncryptParam = (text) => {
  const cipherText = AES.encrypt(text, AUTH)
  return cipherText
}

export const DecryptParam = (cipher) => {
  let originalText = ''
  try {
    var bytes = AES.decrypt(cipher, AUTH)
    originalText = bytes.toString(enc.Utf8)
  } catch (error) {
    return originalText
  }

  return originalText
}

export const EncryptParamJson = (data) => {
  let cipherText = ''
  try {
    cipherText = AES.encrypt(JSON.stringify(data), AUTH)
  } catch (error) {
    return cipherText
  }

  return cipherText
}

export const DecryptParamJson = (cipher) => {
  let originalText = ''
  try {
    var bytes = AES.decrypt(cipher, AUTH)
    originalText = JSON.parse(bytes.toString(enc.Utf8))
  } catch (error) {
    return originalText
  }

  return originalText
}
