import { ThemeProvider, createTheme } from '@mui/material/styles'

// ** Theme
import themeOptions from './ThemeOptions'
import { useSelector } from 'react-redux'

// ** Global Styles

const ThemeComponent = (props) => {
  // ** Props
  const { children } = props
  const mode = useSelector((state) => state.modeReducer.mode)

  // ** Pass merged ThemeOptions (of core and user) to createTheme function
  let theme = createTheme(themeOptions({}, mode === '0' ? 'dark' : 'light'))

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeComponent
