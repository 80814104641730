// ** Util Import

export const hexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '')
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const Autocomplete = (skin) => {
  const boxShadow = (theme) => {
    if (skin === 'bordered') {
      return theme.shadows[0]
    } else if (theme.palette.mode === 'light') {
      return theme.shadows[4]
    } else return '0px 3px 14px 0px rgba(15, 20, 34, 0.38)'
  }

  return {
    MuiAutocomplete: {
      styleOverrides: {
        popper: ({ theme }) => ({
          '.MuiPaper-root': {
            boxShadow: boxShadow(theme),
            ...(skin === 'bordered' && {
              border: `1px solid var(--color-on-base-border-disable)`
            }),
            '& .MuiAutocomplete-option .MuiListItemButton-root:hover': {
              backgroundColor: 'transparent'
            },
            '&.custom-autocomplete-paper': {
              ...theme.typography.body1,
              '& .MuiAutocomplete-option': {
                '&.Mui-focused': {
                  color: 'var(--comp-badge-color-background-primary)',
                  backgroundColor: hexToRGBA(
                    'var(--comp-badge-color-background-primary)',
                    0.08
                  ),
                  '& .MuiTypography-root, & svg': {
                    color: 'inherit'
                  }
                },
                '&[aria-selected="true"]': {
                  color: theme.palette.common.white,
                  backgroundColor: 'var(--comp-badge-color-background-primary)',
                  '& .MuiTypography-root, & svg': {
                    color: 'inherit'
                  }
                },
                '& .MuiCheckbox-root.Mui-checked path:first-of-type': {
                  fill: theme.palette.common.white
                },
                '& .MuiCheckbox-root.Mui-checked path:last-of-type': {
                  fill: 'var(--comp-badge-color-background-primary)',
                  stroke: 'var(--comp-badge-color-background-primary)'
                }
              }
            }
          }
        }),
        inputRoot: {
          '& .MuiAutocomplete-tagSizeSmall': {
            height: 22
          }
        }
      }
    }
  }
}

export default Autocomplete
