import { get } from '../utils/axios'

const path = 'group/'
export const getSystemAPI = async () => {
  return await get(`${path}system`)
}

export const getProfileAPI = async () => {
  return await get(`${path}profile`)
}
