import Swal from 'sweetalert2'
import ImageQuestionWarning from '../../images/img-question-warning.png'
import ImageQuestion from '../../images/img-question.png'
import ImagSuccess from '../../images/img-success.png'
import ImageError from '../../images/img-question-error.png'

export default function SwalCustom({
  text = '',
  title = '',
  confirmButtonColor = '#32BE38',
  showConfirmButton = true,
  showCancelButton = false,
  confirmButtonText = 'Close',
  cancelButtonText = 'Cancel',
  timer,
  cancelButtonColor,
  icon
}) {
  const fnCheckImage = () => {
    if (icon === 'question') {
      return ImageQuestion
    } else if (icon === 'warning') {
      return ImageQuestionWarning
    } else if (icon === 'success') {
      return ImagSuccess
    } else if (icon === 'error') {
      return ImageError
    } else {
      return ImageQuestion
    }
  }
  return Swal.fire({
    html: ` <div class=" container-fluid">
              <div class="row ">
                <div class="col-4 ">
                  <image src="${fnCheckImage()}" class="img-fluid mt-3" />
                </div>
                <div class="col-8 text-start d-block align-self-center">
                  <h4 class="" style="color:${confirmButtonColor}; ">${title}</h4>
                ${
                  text &&
                  `<p class="mb-0" style="color:var(--color-on-base-content-subtle); font-size:16;">${
                    text ?? ''
                  }</p>`
                }  
                </div>
              </div>
            </div>`,
    showCancelButton,
    showConfirmButton,
    confirmButtonColor,
    confirmButtonText,
    cancelButtonText,
    timer,
    cancelButtonColor
  })
}
