import { TEXT_ERROR } from '../../Constants'
import SwalCustom from '../alert/swal-custom'

function ErrorCustomFalse({ msg }) {
  return SwalCustom({
    title: 'พบข้อผิดพลาด',
    text: `${typeof msg === 'object' ? msg[0].msg : msg} <br/>  ${TEXT_ERROR}`,
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: 'var(--comp-badge-color-background-warning)',
    confirmButtonText: 'Close'
  })
}

export default ErrorCustomFalse
