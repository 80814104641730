import { useState, useEffect } from 'react'
import { versionFrontAPI } from '../services/auth'

export default function UseVersionFrontend() {
  const [data, setData] = useState('')
  useEffect(() => {
    const getData = async () => {
      const resp = await versionFrontAPI()
      if (!resp) return

      setData(resp.result.version)
    }
    getData()
  }, [])

  return data
}
