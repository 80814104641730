import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import CardCustom from '../../components/CardCustom'
import FooterLayout from '../../components/layouts/footer'
import ImageLogo from '../../components/custom/ImageLogo'
import ImageBanner from '../../components/custom/ImageBanner'

export default function FooterAuth() {
  return (
    <div>
      <Container fluid className="content-center">
        <Row className="w-100">
          <Col xl={6} className="order-2 order-xl-1 my-auto">
            <ImageBanner />
          </Col>
          <Col
            className="px-xl-2 order-1 order-xl-2 mx-auto my-auto d-flex justify-content-center"
            lg={6}
          >
            <CardCustom
              style={{ width: '350px' }}
              minHeight="none"
              className="pb-3"
            >
              <div className="p-3">
                <Row>
                  <Col xs={12} className="mx-auto text-center mt-3 mb-3">
                    <ImageLogo width={130} />
                  </Col>
                </Row>
                <Outlet />
              </div>
            </CardCustom>
          </Col>
        </Row>
      </Container>
      <FooterLayout className="" />
    </div>
  )
}
