import { combineReducers } from 'redux'
import modeReducer from './mode.reducer'
import loginReducer from './login.reducer'

const rootReducer = combineReducers({
  loginReducer,
  modeReducer
})

export default rootReducer
