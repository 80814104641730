import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { BsExclamationCircle } from 'react-icons/bs'
import { Link, createSearchParams, useNavigate } from 'react-router-dom'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { resetPasswordAPI } from '../../services/auth'
import LabelCustom from '../../components/label-custom'
import ErrorCustomFalse from '../../components/error/ErrorCustomFalse'
import ErrorCustom500 from '../../components/error/ErrorCustom500'
import { PAGE_LOGIN, PAGE_REGISTER_VERIFY } from '../../router/routes'
import { EncryptParam } from '../../lib/crypto'

const schema = yup
  .object({
    email: yup.string().required()
  })
  .required()

export default function ForgotPasswordPage() {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()
  const [isLogin, setIsLogin] = React.useState(false)

  const onSubmit = async (data) => {
    try {
      setIsLogin(true)
      const res = await resetPasswordAPI({
        email: data.email
      })
      setIsLogin(false)
      if (!res) return
      if (res.status) {
        const email = EncryptParam(data.email)
        navigate(
          {
            pathname: PAGE_REGISTER_VERIFY,
            search: `?${createSearchParams({
              id: email
            })}`
          },
          { replace: false }
        )
      } else {
        ErrorCustomFalse(res)
      }
    } catch (error) {
      setIsLogin(false)
      ErrorCustom500(error)
    }
  }
  return (
    <Row className=" mt-3">
      <Col lg={11} className="mx-auto">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <LabelCustom text={'Email'} />
            <Controller
              name={'email'}
              control={control}
              render={({ field: { onChange, value = '' } }) => (
                <Form.Control
                  type="text"
                  placeholder=""
                  id=""
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.email && (
              <span className="text-danger small fw-light">
                <BsExclamationCircle /> Please choose your email.
              </span>
            )}
          </Form.Group>

          <div className="d-grid gap-2 mt-5">
            <Button
              disabled={isLogin}
              className="radius-primary btn-sky"
              type="submit"
            >
              {isLogin ? 'Search...' : 'Search'}
            </Button>
            <Link
              to={PAGE_LOGIN}
              className="btn btn-light"
              variant="light"
              type="button"
            >
              Cancel
            </Link>
          </div>
        </Form>
      </Col>
    </Row>
  )
}
