import React from 'react'
import UseVersionBackend from '../../../hooks/use-version-backend'
import UseVersionFrontend from '../../../hooks/use-version-frontend'

export default function FooterLayout({ isVersion = false }) {
  const api_v = UseVersionBackend()
  const web_v = UseVersionFrontend()

  let isDev = process.env.REACT_APP_IS_DEV
  let _ifIsDev = isDev === '0' ? '-prod' : '-dev'

  let _api = api_v.replace(_ifIsDev, '')
  let _web = web_v.replace(_ifIsDev, '')

  return (
    <div className="" style={{ backgroundColor: 'var(--bg-nav)' }}>
      <div className="p-3 d-flex justify-content-between my-auto">
        <div
          style={{ fontSize: '0.65rem' }}
          className={`fw-light text-center text-muted my-auto`}
        >
          Privacy Policy
        </div>
        <div
          style={{ fontSize: '0.65rem' }}
          className={`fw-light text-center text-muted my-auto`}
        >
          {!isVersion && (
            <label className="me-2">
              COPYRIGHT © {new Date().getFullYear()} IT@SkyICT PCL.All rights
              Reserved
            </label>
          )}
          <label className="">
            API <span className="text-uppercase">{_api}</span> and WEB{' '}
            <span className="text-uppercase">{_web}</span>
          </label>
        </div>
      </div>
    </div>
  )
}
