import React from 'react'
import { Card } from 'react-bootstrap'

function CardCustom(props) {
  const { children, style, minHeight = '80vh', ...other } = props
  return (
    <Card
      {...other}
      style={{
        borderRadius: '1rem',
        border: 0,
        minHeight,
        ...style
      }}
    >
      {children}
    </Card>
  )
}

export default CardCustom
