import SwalCustom from '../components/alert/swal-custom'

function SwalErrorCustom(error) {
  SwalCustom({
    title: 'พบข้อผิดพลาด',
    text: `กรุณาบันทึกภาพหน้าจอ แล้วแจ้ง IT เพื่อแก้ไขปัญหา (Ref. ${error.code})`,
    icon: 'error',
    showCancelButton: false,
    confirmButtonColor: 'var(--comp-badge-color-background-error)',
    confirmButtonText: 'Close'
  })
}

export default SwalErrorCustom
