import { URL_BILLING_DEV, URL_BILLING_PRODUCTION } from '../../../Constants'
import { PAGE_HELPPAGE } from '../../../router/routes'
import ImageBiling from '../../custom/ImageBiling'
import ImageDefault from '../../custom/ImageDefault'

export const fnCheckMenuList = (d) => {
  if (d.id === 0) {
    return {
      id: 0,
      icon: <ImageDefault height={40} />,
      name: d.system,
      link: PAGE_HELPPAGE,
      target: '_self',
      isChcekLogin: false
    }
  } else if (d.id === 1) {
    return {
      id: 1,
      icon: <ImageBiling height={40} />,
      name: d.system,
      link: `${
        process.env.REACT_APP_IS_DEV === '1'
          ? URL_BILLING_DEV
          : URL_BILLING_PRODUCTION
      }/login?tk=`,
      target: '_blank',
      isChcekLogin: true
    }
  } else {
    return {
      id: 0,
      icon: <ImageDefault height={40} />,
      name: d.system,
      link: '/error-404',
      target: '_self',
      isChcekLogin: false
    }
  }
}
