// ** MUI Theme Provider
import { deepmerge } from '@mui/utils'

// ** Theme Override Imports
import palette from './palette'
import spacing from './spacing'
import shadows from './shadows'
import typography from './typography'
import breakpoints from './breakpoints'
import Overrides from './overrides'
import { useSelector } from 'react-redux'

const ThemeOptions = (settings, overrideMode) => {
  // ** Create New object before removing user component overrides and typography objects from userThemeOptions
  const userThemeConfig = Object.assign({}, () => {})
  const mode = useSelector((state) => state.modeReducer.mode)

  const mergedThemeConfig = deepmerge(
    {
      breakpoints: breakpoints(),
      // direction,
      components: Overrides(settings),
      palette: palette(mode === '0' ? 'dark' : 'light', 'default'),
      ...spacing,
      shape: {
        borderRadius: 6
      },
      mixins: {
        toolbar: {
          minHeight: 64
        }
      },
      shadows: shadows(mode === '0' ? 'dark' : 'light'),
      typography
    },
    userThemeConfig
  )

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        contrastText: '#FFF',
        dark: '#655BD3',
        light: '#8479F2',
        main: '#7367F0'
      }
    }
  })
}

export default ThemeOptions
