import { GET_MODE } from '../actions/mode.action'

const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')

const initState = {
  // mode: darkThemeMq.matches ? '1' : '0'
  mode: '1'
}

const modeReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MODE:
      return {
        ...state,
        mode: action.payload.mode
      }
    default:
      return state
  }
}

export default modeReducer
