import { TEXTSUCCESS } from '../../Constants'
import SwalCustom from './swal-custom'

export default function SwalCustomSuccess() {
  return SwalCustom({
    title: 'Success!',
    icon: 'success',
    text: TEXTSUCCESS,
    timer: 1500,
    showConfirmButton: false
  })
}
