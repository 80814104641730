import React from 'react'
import { Image } from 'react-bootstrap'
import LoadingImg from '../../images/loading.svg'
import DivTextCustom from './DivTextCustom'
export default function LoadingCustom() {
  return (
    <div className="d-flex justify-content-center">
      <div>
        <Image src={LoadingImg} />
        <DivTextCustom className="text-center ">Loading...</DivTextCustom>
      </div>
    </div>
  )
}
