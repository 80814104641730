import { PROFILE, RETOKEN, TOKEN } from '../Constants'
import { PAGE_LOGIN } from '../router/routes'
import SwalCustom from '../components/alert/swal-custom'

function SwalErrorToken() {
  SwalCustom({
    title: 'Token time out.',
    text: 'User not found authenticate',
    icon: 'info',
    showCancelButton: false,
    confirmButtonColor: 'var(--mode-primary)',
    confirmButtonText: 'Close'
  }).then(() => {
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(RETOKEN)
    localStorage.removeItem(PROFILE)
    window.open(PAGE_LOGIN, '_self')
  })
}

export default SwalErrorToken
