import React, { useEffect, useState } from 'react'
import NavTitle from '../../components/nav-title'
import CardCustom from '../../components/CardCustom'
import { Col, Row } from 'react-bootstrap'
import { BsChevronDoubleRight } from 'react-icons/bs'
import { MENU_DEFAULT } from '../../router/routes'
import LinkCustom from '../../components/custom/LinkCustom'
import LabelCustom from '../../components/custom/LabelCustom'
import { getProfileAPI } from '../../services/group'
import ErrorCustom500 from '../../components/error/ErrorCustom500'
export default function ProfileIndexPage() {
  const [profile, setProfile] = useState()
  useEffect(() => {
    const getProfile = async () => {
      try {
        const resp = await getProfileAPI()
        if (!resp) return
        if (resp.status) {
          setProfile(resp.result)
        } else {
        }
      } catch (error) {
        ErrorCustom500(error)
      }
    }
    getProfile()
  }, [])

  return (
    <div>
      <NavTitle>
        <LinkCustom to={MENU_DEFAULT}>
          <h5 className="text-muted mb-0">หน้าหลัก</h5>
        </LinkCustom>

        <span className="small opacity-75 my-auto">
          <BsChevronDoubleRight className="ms-1 me-2" />
          ข้อมูลผู้ใช้ (Profile)
        </span>
      </NavTitle>
      <Row>
        <Col xl={6}>
          <CardCustom minHeight="none">
            <div className="p-4">
              {profile ? (
                <h3>{profile.cust_name}</h3>
              ) : (
                <div className="placeholder col-12 h3"></div>
              )}

              <div className="mt-3">
                <LabelCustom className="fw-bold">Address</LabelCustom>

                {profile ? (
                  <p>{profile.addr}</p>
                ) : (
                  <p className="placeholder col-12 "></p>
                )}
              </div>
              <Row>
                <Col xl={6}>
                  <LabelCustom className="fw-bold">Tax ID</LabelCustom>
                  {profile ? (
                    <p> {profile.taxcode}</p>
                  ) : (
                    <p className="placeholder col-12 "></p>
                  )}
                </Col>
                <Col xl={6}>
                  <LabelCustom className="fw-bold">Fax</LabelCustom>

                  {profile ? (
                    <p>{profile.faxno ?? '-'}</p>
                  ) : (
                    <p className="placeholder col-12 "></p>
                  )}
                </Col>
                <Col xl={6}>
                  <LabelCustom className="fw-bold">Tel</LabelCustom>
                  {profile ? (
                    <p> {profile.tax_tel}</p>
                  ) : (
                    <p className="placeholder col-12 "></p>
                  )}
                </Col>

                <Col xl={6}>
                  <LabelCustom className="fw-bold">Mobile</LabelCustom>
                  {profile ? (
                    <p>{profile.mobileno}</p>
                  ) : (
                    <p className="placeholder col-12 "></p>
                  )}
                </Col>
                <Col xl={6}>
                  <LabelCustom className="fw-bold">Email</LabelCustom>
                  {profile ? (
                    <p>{profile.email}</p>
                  ) : (
                    <p className="placeholder col-12 "></p>
                  )}
                </Col>
              </Row>
            </div>
          </CardCustom>
        </Col>
      </Row>
    </div>
  )
}
