import axios from 'axios'
import { API_URL, AUTH, PROFILE, RETOKEN, TOKEN } from '../Constants'
import SwalErrorToken from './swal-error-token'
import SwalErrorCustom from './swal-error'

const API = () => {
  const api = axios.create({
    baseURL: API_URL
  })

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(TOKEN)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return SwalErrorCustom(error)
    }
  )

  api.interceptors.response.use(
    (res) => {
      return res.data
    },
    async (err) => {
      const originalConfig = err.config

      if (err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          try {
            const res = await getRefreshToken()
            if (!res) return
            if (res) {
              api.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${res.accessToken}`
              localStorage.setItem(RETOKEN, res.refreshToken)
              localStorage.setItem(TOKEN, res.accessToken)
              localStorage.setItem(PROFILE, JSON.stringify(res.result[0]))
            } else {
              SwalErrorToken()
            }
            return api(originalConfig)
          } catch (_error) {
            return SwalErrorCustom(_error)
          }
        } else if (err.response.status === 403) {
          return SwalErrorToken()
        }
      }

      return SwalErrorCustom(err)
    }
  )

  async function getRefreshToken() {
    const retoken = localStorage.getItem(RETOKEN)
    return await postJwt('auth/refreshToken', {
      refreshToken: retoken
    })
  }
  const postJwt = async (path, body = {}) => {
    return await axios
      .post(`${API_URL}${path}`, body, {
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          authorization: AUTH
        }
      })
      .then((response) => {
        return response.data
      })
      .catch(() => {
        SwalErrorToken()
      })
  }
  return api
}

export const post = async (path, data) => {
  return await API().post(path, data)
}

export const get = async (path, params = {}) => {
  return await API().get(path, { params })
}

export const put = async (path, params = {}, data = {}) => {
  return await API().put(path, data, { params })
}

export const del = async (path, params = {}) => {
  return await API().delete(path, { params })
}
