import React from 'react'
import { Link } from 'react-router-dom'

export default function LinkCustom({ to = '', children, className = '' }) {
  return (
    <Link
      to={to}
      className={`text-reset ${className} `}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  )
}
