import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import { PAGE_LOGIN } from '../../router/routes'
import PCustom from '../../components/custom/PCustom'
import { DecryptParam } from '../../lib/crypto'
import LoadingCustom from '../../components/custom/LoadingCustom'

export default function VerifyRegisterPage() {
  let [searchParams] = useSearchParams()

  const uuid = searchParams.get('id')
  const [email, setEmail] = useState('')
  useEffect(() => {
    const deEmail = DecryptParam(uuid)
    setEmail(deEmail)
  }, [uuid])

  return email ? (
    <div className="row mt-3">
      <div className="col-lg-11 mx-auto">
        <h5>Verify your email ✉️ </h5>
        <PCustom>
          Account activation link sent to your email{' '}
          <label>
            address: <span className="fw-bold me-1">{email}</span>
          </label>
          Please follow the link inside to continue. If can't find your email,
          please check your junk box.
        </PCustom>
        <Link
          to={PAGE_LOGIN}
          className="btn btn-light w-100 mt-3 "
          variant="light"
          type="button"
        >
          Skip for now
        </Link>
      </div>
    </div>
  ) : (
    <>
      <LoadingCustom />
      <Link
        to={PAGE_LOGIN}
        className="btn btn-light w-100 mt-3 "
        variant="light"
        type="button"
      >
        Skip for now
      </Link>
    </>
  )
}
