import { MODE } from '../Constants'
export const GET_MODE = 'GET_MODE'

export const updateMode = (mode) => {
  return {
    type: GET_MODE,
    payload: {
      mode
    }
  }
}

export const restoreMode = () => {
  const mode = localStorage.getItem(MODE) ?? '1'
  return {
    type: GET_MODE,
    payload: {
      mode
    }
  }
}
