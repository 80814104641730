import React from 'react'

function LabelCustom({ text, subtext = '' }) {
  return (
    <label className="fw-light small">
      {text}{' '}
      {subtext && (
        <span className=" " style={{ color: '#fec203' }}>
          {subtext}
        </span>
      )}
    </label>
  )
}

export default LabelCustom
