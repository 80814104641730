import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { BsExclamationCircle } from 'react-icons/bs'
import { Link, createSearchParams, useNavigate } from 'react-router-dom'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { registerAPI } from '../../services/auth'
import LabelCustom from '../../components/label-custom'
import ErrorCustomFalse from '../../components/error/ErrorCustomFalse'
import ErrorCustom500 from '../../components/error/ErrorCustom500'
import { PAGE_LOGIN, PAGE_REGISTER_VERIFY } from '../../router/routes'
import InputNamberTaxID from '../../components/InputNamberTaxID'
import { EncryptParam } from '../../lib/crypto'

const schema = yup
  .object({
    texID: yup.string().required(),
    email: yup.string().required()
  })
  .required()

function RegisterPage() {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()
  const [isLogin, setIsLogin] = React.useState(false)

  const onSubmit = async (data) => {
    try {
      setIsLogin(true)
      const res = await registerAPI({
        taxNo: data.texID,
        email: data.email
      })
      setIsLogin(false)
      if (!res) return
      if (res.status) {
        const email = EncryptParam(data.email)
        navigate(
          {
            pathname: PAGE_REGISTER_VERIFY,
            search: `?${createSearchParams({
              id: email
            })}`
          },
          { replace: false }
        )
      } else {
        ErrorCustomFalse(res)
      }
    } catch (error) {
      setIsLogin(false)
      ErrorCustom500(error)
    }
  }
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-11 mx-auto">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <LabelCustom text={'Tax ID'} />

              <Controller
                name={'texID'}
                control={control}
                render={({ field: { onChange, value = '' } }) => (
                  <InputNamberTaxID value={value} onChange={onChange} />
                )}
              />

              {errors.texID && (
                <span className="text-danger small fw-light">
                  <BsExclamationCircle /> Please choose your Tex ID.
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <LabelCustom text={'Email'} />
              <Controller
                name={'email'}
                control={control}
                render={({ field: { onChange, value = '' } }) => (
                  <Form.Control
                    type="email"
                    placeholder=""
                    id=""
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.email && (
                <span className="text-danger small fw-light">
                  <BsExclamationCircle /> Please choose your email.
                </span>
              )}
            </Form.Group>

            <div className="d-grid gap-2 mt-5">
              <Button
                disabled={isLogin}
                className="radius-primary btn-sky"
                type="submit"
              >
                {isLogin ? 'Sign up...' : 'Sign up'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className="mt-3 text-center">
        <p>
          Already have an account? <Link to={PAGE_LOGIN}>Sign in instead</Link>
        </p>
      </div>
    </>
  )
}

export default RegisterPage
